import React from 'react'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import { formatDistance } from 'date-fns'
import { es } from 'date-fns/locale'
import { grey } from '@mui/material/colors'

import PropTypes from 'prop-types'

export default function News(props) {
  const { news } = props
  function hasAuthor(n) {
    if (n.author.length > 0) return true
    return false
  }

  function getAuthors(author) {
    return author.map(u => u.name).join(', ')
  }

  function getAvatars(n) {
    return (
      <AvatarGroup max={3}>
        {n.author.map(author => (
          <Avatar
            sx={{ bgcolor: grey[500] }}
            key={author.name}
            alt={author.name}
            src={author.avatar}
          />
        ))}
        <Avatar sx={{ bgcolor: grey[500] }} alt={n.media.avatar} src={n.media.avatar} />
      </AvatarGroup>
    )
  }

  return (
    <Grid container spacing={5}>
      {news.news
        .filter(n => hasAuthor(n))
        .map(n => (
          <Grid item xs={12} sm={6} md={4} key={n.id}>
            {
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  ':hover': {
                    boxShadow: 10,
                    bgcolor: 'rgb(77, 77, 77)', // theme.shadows[20]
                  },
                }}
                onClick={() => {
                  window.open(n.url, '_blank')
                }}
              >
                <CardHeader
                  avatar={getAvatars(n)}
                  action={
                    <IconButton aria-label="share">
                      <ShareIcon />
                    </IconButton>
                  }
                  title={getAuthors(n.author)}
                  subheader={formatDistance(
                    new Date(n.timestamp.replace('-03:00', 'Z')),
                    new Date(),
                    {
                      addSuffix: true,
                      locale: es,
                    },
                  )}
                />

                {n.extra && n.extra['og:image'] && (
                  <CardMedia component="img" height="194" image={n.extra['og:image']} />
                )}

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {n.title}
                  </Typography>
                  {/* <Typography variant="body2" color="text.secondary">
                            Lizards are a widespread group of squamate reptiles, with over 6,000
                            species, ranging across all continents except Antarctica
                            </Typography> */}
                </CardContent>

                <CardActions sx={{ justifyContent: 'right' }}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      window.open(n.url, '_blank')
                    }}
                  >
                    Ir a la nota
                  </Button>
                  {/* <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                            </IconButton>
                            <IconButton aria-label="share">
                            <ShareIcon />
                            </IconButton> */}
                </CardActions>
              </Card>
            }
          </Grid>
        ))}
    </Grid>
  )
}

News.propTypes = {
  news: PropTypes.object.isRequired,
}
