import axios from 'axios'
import config from '../config'

const axiosClient = axios.create({ baseURL: config.apiUrl })

const getNews = async limit => {
  const response = await axiosClient.get(`/news?page=1&limit=${limit}`)
  return response.data
}

const getNewsByJournalist = async (limit, journalistID) => {
  const response = await axiosClient.get(`/news?limit=${limit}&authorId=${journalistID}`)
  return response.data
}

const getJournalists = async name => {
  const response = await axiosClient.get(`/journalists?${name ? `name=${name}` : ''}`)
  return response.data
}

export default { getNews, getNewsByJournalist, getJournalists }
