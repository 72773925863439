/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo } from 'react'
import { useQuery } from 'react-query'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'

import ApplicationBar from './ApplicationBar'
import { AutocompleteJournalist } from './AutocompleteJournalist'
import News from './News'
import Copyright from './Copyright'

import api from '../api'
import config from '../config'

function Principal() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [author, setAuthor] = useState(null)
  const fetchNews = () =>
    author ? api.getNewsByJournalist(config.apiLimit, author) : api.getNews(config.apiLimit)
  const { data: news, isFetching } = useQuery(['news', author], () => fetchNews())

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApplicationBar />
      <main>
        <Box sx={{ bgcolor: 'background.paper', pt: 6, pb: 0 }}>
          <Container maxWidth="xl">
            <Typography
              component="h1"
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Periodistas
            </Typography>
          </Container>
        </Box>
        <Box
          sx={{
            width: 'auto',
            maxWidth: 380,
            position: 'relative',
            margin: '0 auto',
          }}
        >
          <AutocompleteJournalist onJournalistSelected={journalistId => setAuthor(journalistId)} />
        </Box>
        <Container sx={{ py: 8 }} maxWidth="xl">
          {isFetching && (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {news && <News news={news} />}
        </Container>
        <Copyright />
      </main>
    </ThemeProvider>
  )
}

export default Principal
