import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import { useDebounce } from 'use-debounce'
import { CircularProgress } from '@mui/material'

import api from '../../api'
import { Search, SearchIconWrapper } from './styled'

function AutocompleteJournalist({ onJournalistSelected }) {
  const [selectedJournalist, setSelectedJournalist] = useState(null)
  const [input, setInput] = useState('')
  const [query] = useDebounce(input, 500)

  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const loading = open && options.length === 0

  useEffect(() => {
    const fetch = async () => {
      const response = await api.getJournalists(query)
      setOptions(response.journalists)
    }
    fetch()
  }, [query])

  useEffect(() => {
    if (selectedJournalist) {
      onJournalistSelected(selectedJournalist.id)
    } else {
      onJournalistSelected(null)
    }
  }, [onJournalistSelected, selectedJournalist])

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Autocomplete
        id="journalist"
        freeSolo
        filterOptions={x => x}
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            label=""
            variant="standard"
            sx={{ padding: '0px 0px 0px 45px', maxWidth: 360 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        onInputChange={(event, newInputValue) => {
          setInput(newInputValue)
        }}
        getOptionLabel={option => (option.name ? option.name : option)}
        value={selectedJournalist}
        onChange={(_event, newJournalist) => {
          setSelectedJournalist(newJournalist)
        }}
      />
    </Search>
  )
}

export { AutocompleteJournalist }
